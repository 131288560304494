import React from 'react'
import './torusHeader.css'
import image1 from '../../Assets/Why Torus/torus-icon.png'
const TorusHeader = () => {
  return (
    <div className='Torus-Header'>
        <img src={image1} alt='' className='whytorus-header-pic'/>
    </div>
  )
}

export default TorusHeader